// src/components/Footer.js
import React from 'react';
import { Container } from 'react-bootstrap';

function Footer() {
  return (
    <footer className="bg-dark text-white text-center py-3">
      <Container>
        <p>&copy; 2024 Ma Boutique - Tous droits réservés</p>
        <p><a href="/mentions-legales" className="text-white">Mentions légales</a></p>
      </Container>
    </footer>
  );
}

export default Footer;

