// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import PublicPromoList from './components/PublicPromoList';
import AdminBoutique from './components/AdminBoutique';
import Login from './components/Login';
import SearchAliExpress from './components/SearchAliExpress';  // Importation de SearchAliExpress

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('Utilisateur connecté :', user);  // Debugging log
        setUser(user);
      } else {
        console.log('Aucun utilisateur connecté');  // Debugging log
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <div className="App">
        <Header user={user} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/promos" element={<PublicPromoList />} />
          <Route path="/login" element={<Login onLogin={(user) => setUser(user)} />} />
          
          {/* Protéger l'accès aux routes admin */}
          {user && user.email === 'ahmed@gmail.com' ? (
            <>
              <Route path="/admin" element={<AdminBoutique />} />
              <Route path="/admin/search" element={<SearchAliExpress />} />  {/* Route pour la recherche */}
            </>
          ) : (
            <Route path="*" element={<p>Vous n'êtes pas autorisé à accéder à cette page.</p>} />
          )}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

