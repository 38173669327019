// src/components/Home.js
import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <Container fluid className="text-center bg-dark text-white p-5 custom-jumbotron">
      <h1>Bienvenue sur notre plateforme de promotions</h1>
      <p>Découvrez les meilleures offres et promotions exclusives.</p>
      <Button variant="primary" as={Link} to="/promos">
        Voir les promotions
      </Button>
    </Container>
  );
}

export default Home;

