// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBAlA5mTcEPNhaCrU3xReMMQ7rhyY50F34",
  authDomain: "kwasareglobal.firebaseapp.com",
  projectId: "kwasareglobal",
  storageBucket: "kwasareglobal.appspot.com",
  messagingSenderId: "590999071028",
  appId: "1:590999071028:web:f374b6aba00af47c069d61"
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);

// Initialiser Firestore
const db = getFirestore(app);

const auth = getAuth(app);  // Assure-toi que 'auth' est bien initialisé

export { db, auth };  // Assure-toi que 'auth' est bien exporté
