// src/components/Header.js
import React from 'react';
import { Navbar, Nav, Button, Form, FormControl, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { FaHome, FaTags, FaUserCircle, FaSignOutAlt, FaSearch } from 'react-icons/fa'; // Importer des icônes

function Header({ user }) {
  const handleLogout = async () => {
    await signOut(auth);
  };

  return (
    <Navbar bg="light" expand="lg" className="shadow-sm">
      {/* Logo de la boutique */}
      <Navbar.Brand as={Link} to="/" className="font-weight-bold">
        Ma Boutique
      </Navbar.Brand>

      {/* Barre de recherche au centre */}
      <Form className="mx-auto d-none d-lg-block" style={{ width: '300px' }}>
        <div className="input-group">
          <FormControl
            type="text"
            placeholder="Rechercher..."
            className="search-bar"
          />
          <div className="input-group-append">
            <Button variant="outline-secondary">
              <FaSearch />
            </Button>
          </div>
        </div>
      </Form>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto d-flex align-items-center">
          {/* Lien vers Accueil */}
          <Nav.Link as={Link} to="/">
            <FaHome className="mr-2" /> Accueil
          </Nav.Link>

          {/* Lien vers Promotions */}
          <Nav.Link as={Link} to="/promos">
            <FaTags className="mr-2" /> Promotions
          </Nav.Link>

          {!user ? (
            <>
              {/* Connexion si non connecté */}
              <Nav.Link as={Link} to="/login">
                Connexion
              </Nav.Link>
            </>
          ) : (
            <>
              {/* Si admin, afficher lien Admin et Recherche AliExpress */}
              {user.email === 'ahmed@gmail.com' && (
                <>
                  <Nav.Link as={Link} to="/admin">
                    Admin
                  </Nav.Link>
                  <Nav.Link as={Link} to="/admin/search">
                    Recherche AliExpress
                  </Nav.Link>
                </>
              )}

              {/* Menu déroulant pour l'utilisateur connecté */}
              <Nav.Link as={Link} to="/profile" className="d-flex align-items-center">
                <Image
                  src={user.photoURL || "https://via.placeholder.com/30"} // Image de profil ou placeholder
                  alt="User"
                  roundedCircle
                  style={{ width: '30px', marginRight: '10px' }}
                />
                {user.displayName || "Profil"}
              </Nav.Link>

              {/* Déconnexion */}
              <Button variant="outline-danger" className="ml-2" onClick={handleLogout}>
                <FaSignOutAlt className="mr-1" /> Déconnexion
              </Button>
            </>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
// src/components/Header.js
