// src/components/PublicPromoList.js
import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container, Button, Badge, Form, Pagination } from 'react-bootstrap';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';  // Importe la configuration Firebase
import { FaComment, FaThumbsUp, FaArrowUp } from 'react-icons/fa';  // Icons

const PROMOS_PER_PAGE = 30;  // Limite à 30 promos par page

function PublicPromoList() {
  const [promos, setPromos] = useState([]);  // Stocker les promos
  const [filteredPromos, setFilteredPromos] = useState([]);  // Stocker les promos filtrées
  const [currentPage, setCurrentPage] = useState(1);  // Page actuelle pour la pagination
  const [loading, setLoading] = useState(true);  // Indicateur de chargement
  const [filters, setFilters] = useState({ price: '', popularity: '', merchant: '' });  // État pour les filtres

  // Fonction pour tronquer la description et ajouter "Lire la suite"
  const truncateDescription = (description, limit) => {
    if (description && description.length > limit) {
      return `${description.substring(0, limit)}...`;
    }
    return description;
  };

  // Fonction pour récupérer toutes les promotions des sous-collections "promo" des utilisateurs
  const fetchAllPromos = async () => {
    try {
      const usersSnapshot = await getDocs(collection(db, "users"));
      let allPromos = [];

      for (const userDoc of usersSnapshot.docs) {
        const userId = userDoc.id;
        const promoSnapshot = await getDocs(collection(db, `users/${userId}/promo`));
        promoSnapshot.forEach((promoDoc) => {
          allPromos.push({ ...promoDoc.data(), id: promoDoc.id, userId });
        });
      }

      setPromos(allPromos);  // Mettre à jour l'état avec toutes les promos
      setFilteredPromos(allPromos);  // Initialiser les promos filtrées
    } catch (error) {
      console.error("Erreur lors de la récupération des promotions :", error);
    } finally {
      setLoading(false);  // Arrêter le chargement
    }
  };

  // Utilise useEffect pour charger toutes les promos au montage du composant
  useEffect(() => {
    fetchAllPromos();  // Récupérer les promotions au montage du composant
  }, []);

  // Fonction pour gérer la pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Gestion des filtres
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
    applyFilters({ ...filters, [name]: value });
  };

  const applyFilters = (updatedFilters) => {
    let filtered = [...promos];

    // Filtrer par prix
    if (updatedFilters.price === 'asc') {
      filtered = filtered.sort((a, b) => a.price - b.price);
    } else if (updatedFilters.price === 'desc') {
      filtered = filtered.sort((a, b) => b.price - a.price);
    }

    // Filtrer par popularité
    if (updatedFilters.popularity === 'asc') {
      filtered = filtered.sort((a, b) => a.nbLike - b.nbLike);
    } else if (updatedFilters.popularity === 'desc') {
      filtered = filtered.sort((a, b) => b.nbLike - a.nbLike);
    }

    // Filtrer par marchand (supposons qu'il y ait un champ "nomBoutique" dans les données)
    if (updatedFilters.merchant) {
      filtered = filtered.filter(promo => promo.nomBoutique && promo.nomBoutique.toLowerCase().includes(updatedFilters.merchant.toLowerCase()));
    }

    setFilteredPromos(filtered);  // Mettre à jour les promotions filtrées
    setCurrentPage(1);  // Réinitialiser à la première page après le filtrage
  };

  // Calcul des promos à afficher sur la page actuelle
  const indexOfLastPromo = currentPage * PROMOS_PER_PAGE;
  const indexOfFirstPromo = indexOfLastPromo - PROMOS_PER_PAGE;
  const currentPromos = filteredPromos.slice(indexOfFirstPromo, indexOfLastPromo);  // Promos pour la page actuelle

  // Calcul du nombre total de pages
  const totalPages = Math.ceil(filteredPromos.length / PROMOS_PER_PAGE);

  return (
    <Container fluid>
      <Row>
        {/* Barre latérale */}
        <Col md={3} className="d-none d-md-block">
          <div className="bg-light p-3 rounded shadow-sm">
            <h5 className="text-center">Meilleurs codes promo</h5>
            <ul className="list-unstyled">
              <li><button className="link-button">Amazon</button></li>
              <li><button className="link-button">Cdiscount</button></li>
              <li><button className="link-button">Fnac</button></li>
              <li><button className="link-button">Rakuten</button></li>
            </ul>
          </div>
        </Col>

        {/* Liste des promotions */}
        <Col md={9}>
          <h2 className="text-center mb-4">Promotions en cours</h2>

          {/* Filtres */}
          <Row className="mb-4">
            <Col md={4}>
              <Form.Select name="price" value={filters.price} onChange={handleFilterChange}>
                <option value="">Trier par prix</option>
                <option value="asc">Prix croissant</option>
                <option value="desc">Prix décroissant</option>
              </Form.Select>
            </Col>
            <Col md={4}>
              <Form.Select name="popularity" value={filters.popularity} onChange={handleFilterChange}>
                <option value="">Trier par popularité</option>
                <option value="asc">Popularité croissante</option>
                <option value="desc">Popularité décroissante</option>
              </Form.Select>
            </Col>
            <Col md={4}>
              <Form.Control
                type="text"
                placeholder="Chercher par marchand"
                name="merchant"
                value={filters.merchant}
                onChange={handleFilterChange}
              />
            </Col>
          </Row>

          {loading ? (
            <p className="text-center">Chargement des promotions...</p>
          ) : (
            <>
              <Row>
                {currentPromos.length > 0 ? (
                  currentPromos.map((promo) => (
                    <Col md={12} key={promo.id} className="mb-4">
                      <Card className="shadow-sm d-flex flex-row align-items-center promo-card">
                        {/* Image du produit */}
                        {promo.image && promo.image[0] && (
                          <Card.Img variant="left" src={promo.image[0]} alt={promo.name} className="promo-image" />
                        )}
                        <Card.Body>
                          <Row>
                            <Col>
                              <Card.Title className="promo-title">{promo.name}</Card.Title>
                              <Card.Text className="text-muted promo-description">{truncateDescription(promo.description, 100)}</Card.Text>
                              <Card.Text>
                                <strong className="text-danger promo-price">{promo.sale_price} €</strong>{' '}
                                <del className="text-muted promo-original-price">{promo.price} €</del>{' '}
                                <span className="text-success promo-discount">-{Math.round((promo.price - promo.sale_price) / promo.price * 100)}%</span>
                              </Card.Text>
                              <Card.Text className="text-muted small promo-author">
                                Ajouté par l'utilisateur : {promo.userId}
                              </Card.Text>
                            </Col>
                            <Col md="auto" className="text-center promo-icons">
                              <FaArrowUp className="text-warning promo-popularity" /> <Badge pill bg="light" className="text-dark promo-popularity-badge">53°</Badge><br />
                              <FaComment className="text-muted promo-comment-icon" /> {promo.nbComment}<br />
                              <FaThumbsUp className="text-primary promo-like-icon" /> {promo.nbLike}
                            </Col>
                          </Row>
                          <div className="d-flex justify-content-end mt-3">
                            <Button variant="primary promo-button">Voir le deal</Button>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <Col>
                    <p>Aucune promotion trouvée.</p>
                  </Col>
                )}
              </Row>

              {/* Pagination */}
              {filteredPromos.length > PROMOS_PER_PAGE && (
                <Pagination className="justify-content-center mt-4 promo-pagination">
                  {[...Array(totalPages)].map((_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                </Pagination>
              )}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default PublicPromoList;

