import React, { useState } from 'react';
import { db } from '../firebase'; // Importer la configuration Firebase
import { collection, addDoc, Timestamp } from 'firebase/firestore'; // Importer Firestore pour ajouter des données

function SearchAliExpress() {
  const [query, setQuery] = useState(''); // Terme de recherche
  const [results, setResults] = useState([]); // Stocker les résultats de l'API
  const [loading, setLoading] = useState(false); // Indicateur de chargement
  const [error, setError] = useState(null); // Gestion des erreurs
  const [priceMultiplier, setPriceMultiplier] = useState(1); // Multiplicateur de prix

  // Fonction pour effectuer l'appel API
  const searchItems = async () => {
    setLoading(true); // Indique que le chargement a commencé
    setError(null); // Réinitialiser l'erreur
    setResults([]); // Réinitialiser les résultats

    const apiUrl = `https://aliexpress-datahub.p.rapidapi.com/item_search_2?q=${query}&page=1&sort=default`;

    try {
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'x-rapidapi-host': 'aliexpress-datahub.p.rapidapi.com',
          'x-rapidapi-key': '6bb7c32f58mshc9593a6752b037ep1eee25jsnc9eb4d5c84eb',
        },
      });
      const data = await response.json();
      
      // Parsing de la réponse JSON
      const parsedResults = data.result.resultList.map((result) => ({
        id: result.item.itemId,
        title: result.item.title,
        price: result.item.sku.def.price,
        promotionPrice: result.item.sku.def.promotionPrice,
        imageUrl: result.item.image,
        productUrl: result.item.itemUrl,
        sales: result.item.sales,
        rating: result.item.averageStarRate,
      }));
      
      setResults(parsedResults); // Stocker les résultats
    } catch (err) {
      setError('Une erreur est survenue lors de la recherche.');
    } finally {
      setLoading(false); // Arrête le chargement
    }
  };

  // Fonction pour ajouter un produit dans la collection "boutique" de Firebase
  const addProductToBoutique = async (product) => {
    try {
      const finalPrice = product.promotionPrice * priceMultiplier; // Calculer le prix final
      const salePrice = product.promotionPrice ? finalPrice : null;
      const price = product.price * priceMultiplier;

      await addDoc(collection(db, "boutique"), {
        nomCategorie: "AliExpress",  // Exemple de catégorie générique, à modifier si nécessaire
        lienBoutique: `https:${product.productUrl}`,  // Lien du produit AliExpress
        quantity: 1,  // Quantité par défaut, peut être modifiable si nécessaire
        refCatgorie: null,  // Peut être laissé vide si aucune référence
        description: product.title,  // Utiliser le titre du produit comme description
        created_at: Timestamp.now(),  // Timestamp actuel
        sale_price: salePrice,  // Prix en promotion
        price: price,  // Prix original multiplié
        imagesProduit: [product.imageUrl],  // Utiliser l'image du produit
        on_sale: !!product.promotionPrice,  // Si un prix promotionnel existe, on_sale est true
        name: product.title,  // Titre du produit comme nom
        dejaOffer: 0,  // Valeur par défaut pour "dejaOffer"
      });

      alert(`Produit "${product.title}" ajouté avec succès à la boutique !`);
    } catch (error) {
      console.error("Erreur lors de l'ajout du produit :", error);
    }
  };

  // Gérer la soumission du formulaire de recherche
  const handleSubmit = (e) => {
    e.preventDefault(); // Empêche le rafraîchissement de la page
    searchItems(); // Effectuer la recherche
  };

  return (
    <div className="search-results">
      <h2>Recherche AliExpress</h2>

      {/* Formulaire de recherche */}
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Rechercher un produit..."
          required
        />
        <button type="submit">Chercher</button>
      </form>

      {/* Input pour le multiplicateur de prix */}
      <div style={{ margin: '20px 0' }}>
        <label>Multiplicateur de prix : </label>
        <input
          type="number"
          value={priceMultiplier}
          onChange={(e) => setPriceMultiplier(e.target.value)}
          min="1"
          step="0.01"
          style={{ width: '100px', marginLeft: '10px' }}
        />
      </div>

      {/* Indicateur de chargement */}
      {loading && <p>Chargement des résultats...</p>}
      
      {/* Gestion des erreurs */}
      {error && <p>{error}</p>}
      
      {/* Affichage des résultats */}
      {!loading && !error && results.length > 0 && (
        <ul>
          {results.map((item) => (
            <li key={item.id} className="item-card">
              <img
                src={item.imageUrl}
                alt={item.title}
                style={{ width: '100px', height: '100px' }}
              />
              <div>
                <p><strong>{item.title}</strong></p>
                <p>Prix : {item.promotionPrice ? `${item.promotionPrice} €` : 'Non disponible'}</p>
                {item.price && item.promotionPrice && (
                  <p>
                    <del>{item.price} €</del>
                  </p>
                )}
                <p>Ventes : {item.sales}</p>
                {item.rating && <p>Note : {item.rating}/5</p>}

                {/* Bouton pour ajouter le produit à la collection "boutique" */}
                <button onClick={() => addProductToBoutique(item)}>
                  Ajouter le produit
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}

      {!loading && !error && results.length === 0 && <p>Aucun résultat trouvé.</p>}
    </div>
  );
}

export default SearchAliExpress;

