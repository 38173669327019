// src/components/AdminBoutique.js
import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container, Button } from 'react-bootstrap';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';  // Importe la configuration Firebase

function AdminBoutique() {
  const [boutiqueItems, setBoutiqueItems] = useState([]);  // Utilise un état pour stocker les items de la boutique

  // Fonction pour récupérer les produits de la collection "boutique"
  const fetchBoutiqueItems = async () => {
    const querySnapshot = await getDocs(collection(db, "boutique"));
    const items = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setBoutiqueItems(items);
  };

  // Fonction pour supprimer un produit
  const handleDelete = async (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet article ?")) {
      await deleteDoc(doc(db, "boutique", id));
      fetchBoutiqueItems(); // Rafraîchir la liste après suppression
    }
  };

  // Utilise useEffect pour charger les produits au montage du composant
  useEffect(() => {
    fetchBoutiqueItems();
  }, []);

  return (
    <Container>
      <h2 className="text-center mb-4">Gestion des produits de la boutique</h2>
      <Row>
        {boutiqueItems.map((item) => (
          <Col md={4} key={item.id} className="mb-4">
            <Card>
              <Card.Img variant="top" src={item.imagesProduit[0]} alt={item.name} />
              <Card.Body>
                <Card.Title>{item.name}</Card.Title>
                <Card.Text>{item.description}</Card.Text>
                <Card.Text>
                  <strong>Prix : {item.price} €</strong>
                  {item.on_sale && (
                    <>
                      <br />
                      <strong>Prix soldé : {item.sale_price} €</strong>
                    </>
                  )}
                </Card.Text>
                <Card.Text>
                  Quantité disponible : {item.quantity}
                </Card.Text>
                {item.on_sale && (
                  <span className="badge bg-success">En promotion</span>
                )}
                {/* Boutons de modification et de suppression */}
                <Button variant="warning" href={`/edit-product/${item.id}`} className="mr-2">Modifier</Button>
                <Button variant="danger" onClick={() => handleDelete(item.id)}>Supprimer</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default AdminBoutique;

